import React from "react";

export default function Header() {
    return (
        <header className="header" id="header">
            <div className="logoBox">
                <a href="/" className="nav_logo">
                    <img src="./img/logo.png" alt="CityVerse Logo" />
                </a>
            </div>
            <div>
                <a href="https://dapp.cityverse.finance" className="dapp-btn">
                    Connect dApp
                </a>
            </div>
        </header>
    );
}
