import React from "react";
import {Routes, Route} from "react-router-dom";
import HomePage from "./Pages/HomePage";
import MarketPlace from "./Pages/MarketPlace";

export default function App() {
  return (
    <Routes>
      <Route exact path="/marketplace" element={<MarketPlace />} />
      <Route exact path="/" element={<HomePage />} />
    </Routes>
  );
}
