import React from "react";

export default function Footer() {
    return (
        <div className="footer generic">
            <div className="p-50">
                <div className="row">
                    <div className="col-md-3 col-12 fastmenu">
                        <h6>+ FAST MENU</h6>
                        <a href="/">Homepage</a>
                        <a href="https://dapp.cityverse.finance">Map</a>
                        <a href="/marketplace">Marketplace</a>
                        <a href="https://docs.cityverse.finance">Docs</a>
                    </div>
                    <div className="col-md-3 col-12 fastmenu socialbox">
                        <h6>+ FOLLOW US</h6>
                        <a href="#">
                            <i className="fa-brands fa-x-twitter" />
                        </a>
                        <a href="#">
                            <i className="fa-brands fa-telegram" />
                        </a>
                    </div>
                    <div className="col-md-6 col-12 text-md-end text-white-50 footer-about">
                        <img src="./img/logo.png" alt="" />
                        <p>
                            CityVerse is an interactive virtual city-building experience where
                            players can purchase land through a crypto-based browser game,
                            construct various structures, and earn CityVerse tokens based on
                            the type of building they establish.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
