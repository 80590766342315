import React from "react";
import { NavLink } from 'react-router-dom';

export default function Navbar() {
    return (
        <div className="l-navbar show" id="nav-bar">
            <nav className="nav">
                <div>
                    <div className="nav_list">
                        <NavLink activeClassName="active" to="/" className="nav_link">
                            <i className="bx bxs-square nav_icon" />
                            <span className="nav_name">Homepage</span>
                        </NavLink>
                        <NavLink activeClassName="active" to="https://dapp.cityverse.finance" className="nav_link">
                            <i className="bx bx-map nav_icon" />
                            <span className="nav_name">Map</span>
                        </NavLink>
                        <NavLink activeClassName="active" to="/marketplace" className="nav_link">
                            <i className="bx bx-store-alt nav_icon" />
                            <span className="nav_name">Marketplace</span>
                        </NavLink>
                        <NavLink activeClassName="active" to="https://docs.cityverse.finance" className="nav_link">
                            <i className="bx bx-file" />
                            <span className="nav_name">Docs</span>
                        </NavLink>
                        <NavLink activeClassName="active" to="https://x.com/" className="nav_link">
                            <i className="fa-brands fa-x-twitter" />
                            <span className="nav_name">X</span>
                        </NavLink>
                        <NavLink activeClassName="active" to="https://t.me/" className="nav_link">
                            <i className="bx bxl-telegram" />
                            <span className="nav_name">Telegram</span>
                        </NavLink>
                    </div>
                </div>
            </nav>
        </div>
    );
}
