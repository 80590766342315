import React from "react";
import Header from "../Components/header";
import Footer from "../Components/footer";
import Navbar from "../Components/navbar";

export default function MarketPlace() {
    return (
        <>
            {<Header />}
            {<Navbar />}
            <main className="height-100 body-pd">

                <section className="comingSoon mt-5">
                    <img src="./img/coming.svg" alt=""/>

                    <h1 className="text-center text-white">Coming Soon</h1>
                    <p className="text-center text-white"><b>Very soon</b>, you will be able to sell your houses and lands <br/> on the <b>Marketplace</b> at the price you set.</p>
                </section>

                {<Footer />}
            </main>
        </>
    );
}
