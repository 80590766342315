import React from "react";
import Footer from "../Components/footer";
import Header from "../Components/header";
import Navbar from "../Components/navbar";

export default function HomePage() {
    return (
        <>
            {<Header />}
            {<Navbar />}
            <main className="height-100 body-pd">
                <section className="banner-section">
                    <div
                        className="banner"
                        style={{ backgroundImage: 'url("./img/banner.png")' }}
                    >
                        <div className="banner-caption">
                            <div className="tag-box">
                                <span className="landsale">Land Sale</span>
                                <div className="tags live">
                                    <div className="reveal-dot" />
                                    LIVE
                                </div>
                            </div>
                            <div className="banner-text">
                                <h1>BECOME A LAND OWNER, MEET THE PERFECT CITY</h1>
                                <p>
                                    Now you can buy your land and establish your <br /> home or
                                    business in our perfect city.
                                </p>
                                <a className="btn" href="https://dapp.cityverse.finance">
                                    Buy Land
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="nfts">
                    <div className="h3-container mb-5">
                        <h3 className="text-white">Building Types</h3>
                        <p className="text-white">
                            You can see the building types and their benefits below.
                        </p>
                    </div>
                    <div className="row">
                        <div className="nft-box">
                            <div className="box-inner">
                                <img src="./img/nfts/house_1.png" alt="Home Building" />
                                <h4>Home</h4>
                                <hr />
                                <p className="mb-1">
                                    Supply: <span>80</span>
                                </p>
                                <p className="mb-1">
                                    Price: <span>$1000</span>
                                </p>
                                <p className="mb-1">
                                    Bonus Rate: <span>%10</span>
                                </p>
                            </div>
                        </div>
                        <div className="nft-box">
                            <div className="box-inner">
                                <img src="./img/nfts/residence_1.png" alt="Residence Building" />
                                <h4>Residence</h4>
                                <hr />
                                <p className="mb-1">
                                    Supply: <span>150</span>
                                </p>
                                <p className="mb-1">
                                    Price: <span>$2500</span>
                                </p>
                                <p className="mb-1">
                                    Bonus Rate: <span>%8</span>
                                </p>
                            </div>
                        </div>
                        <div className="nft-box">
                            <div className="box-inner">
                                <img src="./img/nfts/commercial_1.png" alt="Commercial Building" />
                                <h4>Commercial</h4>
                                <hr />
                                <p className="mb-1">
                                    Supply: <span>50</span>
                                </p>
                                <p className="mb-1">
                                    Price: <span>$5000</span>
                                </p>
                                <p className="mb-1">
                                    Bonus Rate: <span>%7</span>
                                </p>
                            </div>
                        </div>
                        <div className="nft-box">
                            <div className="box-inner">
                                <img src="./img/nfts/fabrica_1.png" alt="Factory Building" />
                                <h4>Factory</h4>
                                <hr />
                                <p className="mb-1">
                                    Supply: <span>2</span>
                                </p>
                                <p className="mb-1">
                                    Price: <span>$25000</span>
                                </p>
                                <p className="mb-1">
                                    Bonus Rate: <span>%5</span>
                                </p>
                            </div>
                        </div>
                        <div className="nft-box">
                            <div className="box-inner">
                                <img src="./img/nfts/powerplant_1.png" alt="Power Plant Building" />
                                <h4>Power Plant</h4>
                                <hr />
                                <p className="mb-1">
                                    Supply: <span>1</span>
                                </p>
                                <p className="mb-1">
                                    Price: <span>$50000</span>
                                </p>
                                <p className="mb-1">
                                    Bonus Rate: <span>%3</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="news d-none">
                    <div className="container">
                        <h3 className="mb-4">
                            <span>COMMUNITY</span>
                            <br /> What's new!
                        </h3>
                        <div className="row">
                            <div className="col-md-6 col-12 mb-4">
                                <div
                                    className="blog__list-image is-cursor featured"
                                    style={{
                                        backgroundImage:
                                            'url("https://strapi.sandbox.game/uploads/Paris_Hilton_HP_Banner_50dca6306d.png")'
                                    }}
                                >
                                    <div className="gradient">
                                        <div className="feat-info h3-container">
                                            <div className="wtsnew">What's new!</div>
                                            <h3 className="blog__list-title featured">
                                                Become a Metaverse Icon with Paris Hilton’s Long-Awaited
                                                Avatar Collection!
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 mb-4">
                                <div
                                    className="blog__list-image is-cursor featured"
                                    style={{
                                        backgroundImage:
                                            'url("https://strapi.sandbox.game/uploads/02_K_verse_eng_b606ce3624.png")'
                                    }}
                                >
                                    <div className="gradient">
                                        <div className="feat-info h3-container">
                                            <div className="wtsnew">What's new!</div>
                                            <h3 className="blog__list-title featured">
                                                K-verse ‘Hallyu Rising’ LAND Sale
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-12">
                                <a href="/web/20230601084651/https://www.sandbox.game/en/blog/become-a-metaverse-icon-with-paris-hiltons-long-awaited-avatar-collection/3319">
                                    <div className="blog__list-item small">
                                        <div className="blog__list-image is-cursor small">
                                            <img
                                                src="https://strapi.sandbox.game/uploads/Paris_Hilton_HP_Banner_50dca6306d.png"
                                                alt=""
                                                loading="lazy"
                                            />
                                        </div>
                                        <div className="blog__list-details small">
                                            <div className="blog__list-body is-cursor">
                                                <div className="blog__list-category"> OWN </div>
                                                <div className="blog__list-title small">
                                                    {" "}
                                                    Become a Metaverse Icon with Paris Hilton’s Long-Awaited
                                                    Avatar Collection!{" "}
                                                </div>
                                            </div>
                                            <div className="blog__list-metas">
                                                <div className="blog__list-meta-row">
                                                    {" "}
                                                    May 25, 2023 - 3 min read{" "}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-4 col-12">
                                <a href="/web/20230601084651/https://www.sandbox.game/en/blog/become-a-metaverse-icon-with-paris-hiltons-long-awaited-avatar-collection/3319">
                                    <div className="blog__list-item small">
                                        <div className="blog__list-image is-cursor small">
                                            <img
                                                src="https://strapi.sandbox.game/uploads/Paris_Hilton_HP_Banner_50dca6306d.png"
                                                alt=""
                                                loading="lazy"
                                            />
                                        </div>
                                        <div className="blog__list-details small">
                                            <div className="blog__list-body is-cursor">
                                                <div className="blog__list-category"> OWN </div>
                                                <div className="blog__list-title small">
                                                    {" "}
                                                    Become a Metaverse Icon with Paris Hilton’s Long-Awaited
                                                    Avatar Collection!{" "}
                                                </div>
                                            </div>
                                            <div className="blog__list-metas">
                                                <div className="blog__list-meta-row">
                                                    {" "}
                                                    May 25, 2023 - 3 min read{" "}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-4 col-12">
                                <a href="/web/20230601084651/https://www.sandbox.game/en/blog/become-a-metaverse-icon-with-paris-hiltons-long-awaited-avatar-collection/3319">
                                    <div className="blog__list-item small">
                                        <div className="blog__list-image is-cursor small">
                                            <img
                                                src="https://strapi.sandbox.game/uploads/Paris_Hilton_HP_Banner_50dca6306d.png"
                                                alt=""
                                                loading="lazy"
                                            />
                                        </div>
                                        <div className="blog__list-details small">
                                            <div className="blog__list-body is-cursor">
                                                <div className="blog__list-category"> OWN </div>
                                                <div className="blog__list-title small">
                                                    {" "}
                                                    Become a Metaverse Icon with Paris Hilton’s Long-Awaited
                                                    Avatar Collection!{" "}
                                                </div>
                                            </div>
                                            <div className="blog__list-metas">
                                                <div className="blog__list-meta-row">
                                                    {" "}
                                                    May 25, 2023 - 3 min read{" "}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="generic first" style={{ backgroundImage: 'url("./img/generic-avatar-background.png")' }}>
                    <div className="container">
                        <div className="banner-text h3-container">
                            <h3>Play with your own Avatar</h3>
                            <p>
                                Take your profile pic to the next level. Now you can choose <br />{" "}
                                your favorite Avatar to explore The Sandbox Metaverse.
                            </p>
                            <a href="#" className="custom-button">
                                Create Free Avatar
                            </a>
                        </div>
                    </div>
                </section>
                <section className="gradient-bkg">
                    <div
                        className="w-100 generic"
                        style={{ backgroundImage: 'url("./img/LAND-background.png")' }}
                    >
                        <div className="container mt-4">
                            <div className="row mb-4 pb-3">
                                <div className="col-md-6 col-12">
                                    <img className="land-img" src="./img/plot.png" alt="Plot" />
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="banner-text h3-container">
                                        <h3> Become a LAND owner </h3>
                                        <p className="is-text-semi">
                                            {" "}
                                            Get a piece of LAND from The CityVerse to host your experience
                                            and events, access exclusive content and farming perks, and
                                            get premium defi staking and monetization options.
                                            <a
                                                href="https://dapp.cityverse.finance"
                                                className="link-button"
                                            >
                                                More info <i className="bx bxs-right-arrow-circle" />
                                            </a>
                                        </p>
                                        <div className="buttons-container">
                                            <a href="https://dapp.cityverse.finance" className="buttons-size custom-button" >Buy LAND</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row partners justify-content-center">
                                <div className="h3-container text-center col-md-12 mb-5">
                                    <h3 className="text-white">Partners</h3>
                                </div>
                                <div className="col-md-2 col-6">
                                    <div className="partner-box">
                                        <img src="./img/partners/bsc.svg" alt="" />
                                    </div>
                                </div>
                                <div className="col-md-2 col-6">
                                    <div className="partner-box">
                                        <img src="./img/partners/bscscan.svg" alt="" />
                                    </div>
                                </div>
                                <div className="col-md-2 col-6">
                                    <div className="partner-box">
                                        <img src="./img/partners/cake.svg" alt="" />
                                    </div>
                                </div>
                                <div className="col-md-2 col-6">
                                    <div className="partner-box">
                                        <img src="./img/partners/cmc.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5 justify-content-center">
                                <div className="col-md-2 col-6">
                                    <div className="partner-box">
                                        <img src="./img/partners/coingecko.svg" alt="" />
                                    </div>
                                </div>
                                <div className="col-md-2 col-6">
                                    <div className="partner-box">
                                        <img src="./img/partners/dextools.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="generic first" style={{ backgroundImage: 'url("./img/section-NFT-background.png")' }} >
                    <div className="container">
                        <div className="banner-text h3-container">
                            <h3>Play with your own Avatar</h3>
                            <p>
                                Take your profile pic to the next level. Now you can choose <br />{" "}
                                your favorite Avatar to explore The Sandbox Metaverse.
                            </p>
                            <a href="#" className="custom-button">
                                Create Free Avatar
                            </a>
                        </div>
                    </div>
                </section>
                <section className="generic first" style={{ backgroundImage: 'url("./img/sand-chest-background.png")' }} >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-12" />
                            <div className="col-md-6 col-12">
                                <div className="banner-text h3-container">
                                    <h3>Play with your own Avatar</h3>
                                    <p>
                                        Take your profile pic to the next level. Now you can choose{" "}
                                        <br /> your favorite Avatar to explore The Sandbox Metaverse.
                                    </p>
                                    <a href="#" className="custom-button">
                                        Create Free Avatar
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {<Footer />}
            </main>
        </>
    );
}
